import React from 'react';
import { Link } from 'react-router-dom';

import Icon from '../Icon';

import './styles.css';

function fallbackCopyTextToClipboard(text) {
  const textArea = document.createElement('textarea');
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = 0;
  textArea.style.left = 0;
  textArea.style.position = 'fixed';

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    const successful = document.execCommand('copy');
    successful ? 'successful' : 'unsuccessful';
  } catch (err) {
    console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}

function copyUrl(link) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(link);
    return;
  }
  navigator.clipboard.writeText(link).then(() => {
    console.info('Async: Copying to clipboard was successful!');
  }, (err) => {
    console.error('Async: Could not copy text: ', err);
  });
}

function IconCopy({
  link,
  finalDomain = '',
}) {
  return (
    <div className="item__share__linkT">
      <Link to={link} className="item__footer__link">
        Copy my link to clipboard
      </Link>
      <button type="button" style={{ background: 'none', border: 'none', cursor: 'pointer' }} onClick={() => copyUrl(`${finalDomain}${link}`)} aria-label="ContentCopy">
        <Icon name="ContentCopy" />
      </button>
    </div>
  );
}

export default IconCopy;
